class AjaxComponent {
  constructor() {
    this.baseURL = 'https://api.ad.privatesalon-navi.com/';
  }

  async request(endpoint, method = 'GET', data = null) {
    const url = `${this.baseURL}${endpoint}`;
    const options = {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        // 204 No Contentの場合も許容
        if (response.status !== 204) {
          const errors = await response.json()
          let errorMessage = "";
          if (errors.errors.length > 0) {
            errors.errors.forEach((element, index) => {
              if (index === 0) {
                errorMessage = element;
              } else {
                errorMessage = errorMessage + "\n" + element;
              }
            });
          }

          alert(errorMessage);
          throw new Error(errorMessage);
        }
      }

      // ステータスコードが204の場合、レスポンスのボディは空
      if (response.status === 204) {
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  }

  async get(endpoint) {
    return await this.request(endpoint, 'GET');
  }

  async post(endpoint, data) {
    return await this.request(endpoint, 'POST', data);
  }

  async put(endpoint, data) {
    return await this.request(endpoint, 'PUT', data);
  }

  async delete(endpoint) {
    return await this.request(endpoint, 'DELETE');
  }
}
